import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { AuthService } from '../../appv2/services/auth.service';



@Injectable()
export class AuthorizationGuard implements CanActivate {
    

    constructor(protected router: Router, protected userService: UserService, protected authService: AuthService) {        
    }    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const user = this.userService.GetUser();

        if (user == null || user.isAnonymous) {
            this.router.navigate(['/home']);
            return false;
        }        
        
        if (!user.roles.includes('GrowerUser') && !user.isInternal) {            
            this.authService.Logout('/login-failure');
            }                

        return true;
    }
}
