import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'gol-login-failure',
    templateUrl: './login-failure.component.html',
    styleUrls: []
})

export class LoginFailureComponent {
    constructor(private router: Router) { }
    
    returnHome() {        
        this.router.navigate(['/home']);
    }
    
}