<div class="card-wrapper stage container">
    <gol-section-header>Error</gol-section-header>
    <div class="card-content">
        <router-outlet></router-outlet>
        <div class="card-content-text" >
            <h1 class="title">
                Login Error: User Account Failure
            </h1>
            <div>
                <p>There was an error with your online user account.</p>
                <p>
                    Please contact a Rabobank representative to ensure your account is correctly activated for online access to Grower Online.
                </p>
                <div>
                    <button type="button" class="btn-primary btn-float-left" (click)="returnHome()">Return to Login Page</button>
                </div>
            </div>
        </div>        
    </div>
</div>
  