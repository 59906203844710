import { Routes, RouterModule } from '@angular/router';

import { VERSIONCONFIG } from './version-config';

// import { RegistrationProfileComponent } from '../app/registration/profile/registration-profile.component'; // TODO:MFA
import { RegistrationProfileComponent } from './registration/profile/registration-profile.component'; // TODO:MFA

import { RegistrationLinkAccountComponent } from './registration/profile/registration-linkaccount.component'; // TODO:MFA
// import { RegistrationLinkAccountComponent } from '../app/registration/profile/registration-linkaccount.component'; // TODO:MFA

import { RegistrationAgreementComponent } from './registration/agreement/registration-agreement.component'; // TODO:MFA
// import { RegistrationAgreementComponent } from '../app/registration/agreement/registration-agreement.component'; // TODO:MFA

import { RegistrationSummaryComponent } from './registration/summary/registration-summary.component'; // TODO:MFA

// import { RegistrationComponent } from '../app/registration/registration.component'; // TODO:MFA
import { RegistrationComponent } from './registration/registration.component';

import { BorrowerInformationComponent } from '../app/form/borrower/borrower-information.component';
import { EntityInformationComponent } from '../app/form/entity/entity-information.component';
import { OwnershipComponent } from '../app/form/ownership/ownership.component';
import { FinancialInformationComponent } from '../app/form/balance-sheet/financial-information.component';
import { FinancialInformationIncomeComponent } from '../app/form/income/financial-information-income.component';
import { FinancialInformationBalanceSheetComponent } from '../app/form/balance-sheet/financial-information-balance-sheet.component';
import { SummaryAndSignComponent } from '../app/form/summary/summary-and-sign.component';
import { ConfirmationComponent } from '../app/form/confirmation/confirmation.component';

// import { DashboardHomeComponent } from '../app/dashboard/home/dashboard-home.component'; // TODO:MFA
import { DashboardHomeComponent } from './dashboard/home/dashboard-home.component';
import { UserDashboardComponent } from '../app/dashboard/user-dashboard.component';
import { PaymentsComponent } from '../app/dashboard/account/payments/payments.component';
import { PayoffComponent } from '../app/dashboard/account/payments/payoff.component';
import { AccountDetailsComponent } from '../app/dashboard/account/account-details/account-details.component';
import { AccountTemplateComponent } from '../app/dashboard/account/account-template.component';
import { AccountStatementsComponent } from '../app/dashboard/account/statements/account-statements.component';
import { UserAccountGuard } from '../app/services/user-account-guard';
import { PaymentHistoryComponent } from '../app/dashboard/account/payments/payment-history.component';
import { AmendmentComponent } from '../app/dashboard/account/amendment/amendment.component';
import { AuthorizedPartyComponent } from '../app/dashboard/account/authorized-party/authorized-party.component';
import { AuthorizedPartyAddFormComponent } from '../app/dashboard/account/authorized-party/add-form/add-form.component';
import { AuthorizedPartyInProgressDetailComponent } from '../app/dashboard/account/authorized-party/in-progress-detail/in-progress-detail.component';
import { AuthorizedPartyResponseComponent } from '../app/dashboard/account/authorized-party/response/response.component';
import { TransactionReportComponent } from '../app/dashboard/account/transaction-report/transaction-report.component';

import { AccountPreferencesComponent } from './dashboard/preferences/account-preferences.component';
import { PreferencesContactComponent } from './dashboard/preferences/contact/preferences-contact.component'; // TODO:MFA
import { PreferencesEmailComponent } from './dashboard/preferences/email/preferences-email.component'; // TODO:MFA
import { PreferencesEmailFrameComponent } from './dashboard/preferences/email/preferences-email-frame.component'; // TODO:MFA
import { PreferencesNameComponent } from './dashboard/preferences/name/preferences-name.component';
import { PreferencesPasswordComponent } from './dashboard/preferences/password/preferences-password.component';
import { PreferencesPasswordFrameComponent } from './dashboard/preferences/password/preferences-password-frame.component';
import { PreferencesAlertsComponent } from './dashboard/preferences/alerts/preferences-alerts.component'; // TODO:MFA

import { UserContactGuard } from './services/user-account-guard'; // TODO:MFA
import { AuthorizationGuard } from '../app/services/authorization-guard';
import { TermsAndConditionsComponent } from '../app/terms-and-conditions.component';
import { VendorComponent } from '../app/form/program/vendor.component';
import { NewLandingComponent } from '../app/landing/new-landing.component';
import { HomeComponent } from './home/home.component'; // TODO:MFA
import { B2CLoginComponent } from './form/reusables/account/b2c-login.component'; // TODO:MFA
import { ResetPasswordLandingComponent } from './form/reset-password/reset-password-landing.component'; // TODO:MFA
import { ResetPasswordLoginComponent } from './form/reset-password/reset-password-login.component'; // TODO:MFA
import { FinancialInformationBalanceSheetSelectionComponent } from '../app/form/balance-sheet/financial-information-balance-sheet-selection.component';
import { LineOfBusinessInformationComponent } from '../app/form/line-of-business/line-of-business-information.component';
import { ContactPageComponent } from '../app/contact/contact-page.component';
import { ElectronicStatementComponent } from '../app/electronic-statement.component';
import { AchUSBankStatementComponent } from '../app/ach-us-bank-statement.component';
import { PreApprovalComponent } from '../app/form/preapproval/preapproval.component';
import { MsalGuard } from '@azure/msal-angular';
import { TokenAuthComponent } from 'if-angular-security';
import { NgModule } from '@angular/core';
import { ForgotPasswordLoginComponent } from './form/reusables/account/forgot-password-login.component';
import { ForgotPasswordConfirmationComponent } from './form/reusables/account/forgot-password-confirmation.component';
import { PasswordConfirmationComponent } from './form/reusables/account/password-confirmation.component';
import { ChangeEmailConfirmationComponent} from './dashboard/preferences/email/change-email-confirmation.component';
import { ChangePasswordConfirmationComponent} from './dashboard/preferences/password/change-password-confirmation.component';
import { PreferencesPasswordConfirmationComponent } from './dashboard/preferences/password/preferences-password-confirmation.component';
import { PreferencesEmailConfirmationComponent } from './dashboard/preferences/email/preferences-email-confirmation.component';
import { SiteComponent } from './site.component';
import { AppComponent } from './app.component';
import { LoginFailureComponent } from '../app/form/reusables/account/login-failure.component';

const routes: Routes = [
    {
        path: 'login',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}login`,
    },
    {
        path: 'contact',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}contact`,
    },
    {
        path: 'reset-password',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}reset-password`,
    },
    {
        path: 'program',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}program`,
    },
    {
        path: 'dashboard',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}dashboard`,
    },
    {
        path: 'registration',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}registration`,
    },
    {
        path: 'apply',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}apply`,
    },
    {
        path: 'landing',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}landing`,
    },
    {
        path: 'home',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}home`,
    },
    {
        path: 'borrower-information',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}borrower-information`,
    },
    {
        path: 'entity-information',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}entity-information`,
    },
    {
        path: 'line-of-business-information',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}line-of-business-information`,
    },
    {
        path: 'ownership',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}ownership`,
    },
    {
        path: 'financial-information',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}financial-information`,
    },
    {
        path: 'financial-information-income',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}financial-information-income`,
    },
    {
        path: 'financial-information-balance-sheet',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}financial-information-balance-sheet`,
    },
    {
        path: 'financial-information-balance-sheet-selection',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}financial-information-balance-sheet-selection`,
    },
    {
        path: 'summary-and-sign',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}summary-and-sign`,
    },
    {
        path: 'confirmation',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}confirmation`,
    },
    {
        path: 'terms-and-conditions',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}terms-and-conditions`,
    },
    {
        path: 'electronic-statement',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}electronic-statement`,
    },
    {
        path: 'ach-us-bank-statement',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}ach-us-bank-statement`,
    },
    {
        path: '',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}home`,
        pathMatch: 'full'
    },
    {
        path: 'vendor/:vendor',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}vendor/:vendor`,
    },
    {
        path: 'prescreenedofferacceptance',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}prescreenedofferacceptance`,
    },
    {
        path: 'prescreen',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}prescreen`,
    },
    {
        path: VERSIONCONFIG.path,
        component: AppComponent,    // AppComponent advises if the browser is supported and provides header-free components for B2C content iframes
        children: [
            // the following components have header/footer displayed
            {
                path: '',
                component: SiteComponent,   // SiteComponent provides the header, footer and other static elements
                children: [
                    {
                        path: 'contact',
                        component: ContactPageComponent
                    },
                    {
                        path: 'reset-password',
                        component: ResetPasswordLandingComponent
                    },
                    {
                        path: 'program',
                        component: VendorComponent
                    },
                    {
                        path: '',
                        redirectTo: `/${VERSIONCONFIG.path}/home`,
                        pathMatch: 'full'
                    },
                    { // TODO:MFA
                        path: 'password-confirmation',
                        component: PasswordConfirmationComponent
                    },
                    {
                        path: 'login-failure',
                        component: LoginFailureComponent
                    },
                    {
                        path: 'dashboard',
                        component: UserDashboardComponent,
                        canActivate: [AuthorizationGuard],
                        children: [                            
                            { path: '', redirectTo: 'overview',  pathMatch: 'full'},
                            { path: 'overview', component: DashboardHomeComponent },
                            {
                                path: 'preferences',
                                component: AccountPreferencesComponent,
                                canActivate: [AuthorizationGuard],
                                children: [
                                    { path: '', redirectTo: 'email', pathMatch: 'full' },
                                    { path: 'email', component: PreferencesEmailComponent, data: { tab: 'email' } },
                                    { path: 'email-confirmation', component: PreferencesEmailConfirmationComponent, data: { tab: 'email' } },
                                    { path: 'name', component: PreferencesNameComponent, data: { tab: 'name' } },
                                    { path: 'password', component: PreferencesPasswordComponent, data: { tab: 'password' } },
                                    { path: 'password-confirmation', component: PreferencesPasswordConfirmationComponent, data: { tab: 'password' } },
                                    { path: 'alerts', component: PreferencesAlertsComponent, data: { tab: 'alerts' } },
                                    {
                                        path: 'contact',
                                        component: PreferencesContactComponent,
                                        canActivate: [UserContactGuard],
                                        data: { tab: 'contact' }
                                    }, // TODO:MFA
                                ]
                            },
                            {
                                path: 'account/:accountId',
                                component: AccountTemplateComponent,
                                canActivate: [UserAccountGuard],
                                children: [
                                    { path: '', component: AccountDetailsComponent, data: { tab: 'details' } },
                                    { path: 'payment', component: PaymentsComponent, data: { tab: 'payment' } },
                                    { path: 'payment-history', component: PaymentHistoryComponent, data: { tab: 'payment-history' } },
                                    { path: 'pending-payments', component: PaymentHistoryComponent, data: { tab: 'pending-payments' } },
                                    { path: 'payoff', component: PayoffComponent, data: { tab: 'payoff' } },
                                    { path: 'statements', component: AccountStatementsComponent, data: { tab: 'statements' } },
                                    { path: 'transaction-report', component: TransactionReportComponent, data: { tab: 'transaction-report' } },
                                    {
                                        path: 'authorized-party',
                                        component: AuthorizedPartyComponent,
                                        data: { tab: 'authorized-party' },
                                        children: [
                                            { path: '', component: AuthorizedPartyInProgressDetailComponent, data: { tab: 'authorized-party' } },
                                            { path: 'add', component: AuthorizedPartyAddFormComponent, data: { tab: 'authorized-party' } },
                                            { path: 'response', component: AuthorizedPartyResponseComponent, data: { tab: 'authorized-party' } }
                                        ]
                                    },
                                    { path: 'amendment', component: AmendmentComponent, data: { tab: 'amendment' } },

                                ]
                            }
                        ]
                    },
                    {
                        path: 'registration',
                        component: RegistrationComponent,
                        children: [
                            { path: '', redirectTo: 'registration-profile', pathMatch: 'full' },
                            { path: 'registration-profile', component: RegistrationProfileComponent, data: { tab: 'Profile' } },
                            { path: 'registration-linkaccount', component: RegistrationLinkAccountComponent, data: { tab: 'Account' } },
                            { path: 'registration-agreement', component: RegistrationAgreementComponent, data: { tab: 'Agreement' } },
                            { path: 'registration-summary', component: RegistrationSummaryComponent, data: { tab: 'Summary' } }
                        ]
                    },
                    {
                        path: 'apply',
                        component: NewLandingComponent
                    },
                    {
                        path: 'landing',
                        redirectTo: '/home',
                        pathMatch: 'full'
                    },
                    {
                        path: 'home',
                        component: HomeComponent                        
                    },
                    {
                        path: 'terms-and-conditions',
                        component: TermsAndConditionsComponent
                    },
                    {
                        path: 'electronic-statement',
                        component: ElectronicStatementComponent
                    },
                    {
                        path: 'ach-us-bank-statement',
                        component: AchUSBankStatementComponent
                    },
                    {
                        path: 'prescreenedofferacceptance',
                        component: PreApprovalComponent
                    },
                    {
                        path: 'prescreen',
                        component: PreApprovalComponent
                    },    
                    {
                        path: 'vendor/:vendor',
                        component: NewLandingComponent
                    },
                    {
                        path: 'borrower-information',
                        component: BorrowerInformationComponent
                    },
                    {
                        path: 'entity-information',
                        component: EntityInformationComponent
                    },
                    {
                        path: 'line-of-business-information',
                        component: LineOfBusinessInformationComponent
                    },
                    {
                        path: 'financial-information',
                        component: FinancialInformationComponent
                    },
                    {
                        path: 'financial-information-income',
                        component: FinancialInformationIncomeComponent
                    },
                    {
                        path: 'financial-information-balance-sheet',
                        component: FinancialInformationBalanceSheetComponent
                    },
                    {
                        path: 'financial-information-balance-sheet-selection',
                        component: FinancialInformationBalanceSheetSelectionComponent
                    },
                    {
                        path: 'summary-and-sign',
                        component: SummaryAndSignComponent
                    },
                    {
                        path: 'ownership',
                        component: OwnershipComponent
                    },
                    {
                        path: 'confirmation',
                        component: ConfirmationComponent
                    },
                ],
            },
            // the following components are displayed in B2C content frames without header/footer
            {
                // Dashboard B2C screens 
                path: 'dashboard',
                canActivate: [AuthorizationGuard],
                children: [
                    {
                        path: 'preferences',
                        children: [
                            { path: 'email-frame', component: PreferencesEmailFrameComponent, data: { tab: 'email' } },
                            { path: 'password-frame', component: PreferencesPasswordFrameComponent, data: { tab: 'password' } },
                            { path: 'change-email-confirmation', component: ChangeEmailConfirmationComponent, data: { tab: 'email' } },
                            { path: 'change-password-confirmation', component: ChangePasswordConfirmationComponent, data: { tab: 'password' } },
                        ],
                    },
                ],
            },
            {
                path: 'login',
                component: B2CLoginComponent,
                // canActivate: [AuthorizationGuard],
            },
            {
                path: 'auth',
                component: TokenAuthComponent,
                canActivate: [MsalGuard]
            },
            {
                path: 'reset-password-login',
                component: ResetPasswordLoginComponent
            },
            {
                path: 'forgot-password-login',
                component: ForgotPasswordLoginComponent
            },
            {
                path: 'forgot-password-confirmation',
                component: ForgotPasswordConfirmationComponent
            },
        ]
    },
    // place new paths above here, this catch all redirect needs to be last.
    {
        path: '**',
        redirectTo: `/${VERSIONCONFIG.path}/home`,
        pathMatch: 'full'
    }
];

// TODO:MFA - exporting class instead of const preserve this routes over routes of AppModule v1
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
    export class Routing {
}
