<gol-section-header>My Dashboard</gol-section-header>
<gol-loading-feedback *ngIf="loadingAccounts || loadingApplications"></gol-loading-feedback>
<div class="dashboard" [hidden]="loadingAccounts || loadingApplications">    
    <gol-application-status-summary *ngIf="!hasAccount || (hasAccount && hasOpenApplication)"
    (hasOpenApplication)="returnHasOpenApplication($event)"
    (loadingApplications)="returnLoadingApplications($event)"></gol-application-status-summary>
    <gol-account-summary (hasAccount)="returnHasAccount($event)" (loadingAccounts)="returnloadingAccounts($event)">
    </gol-account-summary>
    <gol-application-status-summary *ngIf="(hasAccount && !hasOpenApplication)"
        (hasOpenApplication)="returnHasOpenApplication($event)"
        (loadingApplications)="returnLoadingApplications($event)"></gol-application-status-summary>
</div>