import { Injectable } from '@angular/core';
import { CanActivate, Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from './user.service';
import { AccountHttpService } from './account-http.service';

@Injectable()
export class UserAccountGuard implements CanActivate {

    constructor(protected router: Router, protected userService: UserService, protected accountService: AccountHttpService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const user = this.userService.GetUser();
        const loan_number = route.params['accountId'];

        if (user != null && !user.isAnonymous && loan_number) {            
            return Promise.resolve(true);             
        } else {            
            this.router.navigate(['/dashboard']);
            return Promise.resolve(false);
        }
    }
}
