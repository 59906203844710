import { Routes, RouterModule } from '@angular/router';

import { RegistrationProfileComponent } from './registration/profile/registration-profile.component';
import { RegistrationLinkAccountComponent } from './registration/profile/registration-linkaccount.component';
import { RegistrationSecurityComponent } from './registration/security/registration-security.component';
import { RegistrationAgreementComponent } from './registration/agreement/registration-agreement.component';
import { RegistrationSummaryComponent } from './registration/summary/registration-summary.component';
import { RegistrationComponent } from './registration/registration.component';

import { BorrowerInformationComponent } from './form/borrower/borrower-information.component';
import { EntityInformationComponent } from './form/entity/entity-information.component';
import { OwnershipComponent } from './form/ownership/ownership.component';
import { FinancialInformationComponent } from './form/balance-sheet/financial-information.component';
import { FinancialInformationIncomeComponent } from './form/income/financial-information-income.component';
import { FinancialInformationBalanceSheetComponent } from './form/balance-sheet/financial-information-balance-sheet.component';
import { SummaryAndSignComponent } from './form/summary/summary-and-sign.component';
import { ConfirmationComponent } from './form/confirmation/confirmation.component';

import { DashboardHomeComponent } from './dashboard/home/dashboard-home.component';
import { UserDashboardComponent } from './dashboard/user-dashboard.component';
import { PaymentsComponent } from './dashboard/account/payments/payments.component';
import { PayoffComponent } from './dashboard/account/payments/payoff.component';
import { AccountDetailsComponent } from './dashboard/account/account-details/account-details.component';
import { AccountTemplateComponent } from './dashboard/account/account-template.component';
import { AccountStatementsComponent } from './dashboard/account/statements/account-statements.component';
import { UserAccountGuard } from './services/user-account-guard';
import { PaymentHistoryComponent } from './dashboard/account/payments/payment-history.component';
import { AmendmentComponent } from './dashboard/account/amendment/amendment.component';
import { AuthorizedPartyComponent } from './dashboard/account/authorized-party/authorized-party.component';
import { AuthorizedPartyAddFormComponent } from './dashboard/account/authorized-party/add-form/add-form.component';
import { AuthorizedPartyInProgressDetailComponent } from './dashboard/account/authorized-party/in-progress-detail/in-progress-detail.component';
import { AuthorizedPartyResponseComponent } from './dashboard/account/authorized-party/response/response.component';
import { TransactionReportComponent } from './dashboard/account/transaction-report/transaction-report.component';

import { AccountPreferencesComponent } from './dashboard/preferences/account-preferences.component';
import { PreferencesContactComponent } from './dashboard/preferences/contact/preferences-contact.component';
import { PreferencesNameComponent } from './dashboard/preferences/name/preferences-name.component';
import { PreferencesPasswordComponent } from './dashboard/preferences/password/preferences-password.component';
import { PreferencesAlertsComponent } from './dashboard/preferences/alerts/preferences-alerts.component';
import { PreferencesSecurityComponent } from './dashboard/preferences/security/preferences-security.component';

import { AuthorizationGuard } from './services/authorization-guard';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { VendorComponent } from './form/program/vendor.component';
import { NewLandingComponent } from './landing/new-landing.component';
import { HomeComponent } from './home/home.component';
import { ResetPasswordLandingComponent } from './form/reset-password/reset-password-landing.component';
import { FinancialInformationBalanceSheetSelectionComponent } from './form/balance-sheet/financial-information-balance-sheet-selection.component';
import { LineOfBusinessInformationComponent } from './form/line-of-business/line-of-business-information.component';
import { ContactPageComponent } from './contact/contact-page.component';
import { ElectronicStatementComponent } from './electronic-statement.component';
import { AchUSBankStatementComponent } from './ach-us-bank-statement.component';
import { PreApprovalComponent } from './form/preapproval/preapproval.component';
import { ResetSecurityQuestionsComponent } from './form/reusables/account/reset-security-questions.component';
import { LoginFailureComponent } from './form/reusables/account/login-failure.component';


const routes: Routes = [
    {
        path: 'contact',
        component: ContactPageComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordLandingComponent
    },
    {
        path: 'program',
        component: VendorComponent
    },
    {
        path: 'dashboard',
        component: UserDashboardComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'login-failure',
                component: LoginFailureComponent
            },
            { path: '', redirectTo: 'overview', pathMatch: 'full' },
            { path: 'overview', component: DashboardHomeComponent },
            {
                path: 'preferences',
                component: AccountPreferencesComponent,
                canActivate: [AuthorizationGuard],
                children: [
                    { path: '', redirectTo: 'contact', pathMatch: 'full' },
                    { path: 'contact', component: PreferencesContactComponent, data: { tab: 'contact' } },
                    { path: 'name', component: PreferencesNameComponent, data: { tab: 'name' } },
                    { path: 'password', component: PreferencesPasswordComponent, data: { tab: 'password' } },
                    { path: 'alerts', component: PreferencesAlertsComponent, data: { tab: 'alerts' } },
                    { path: 'security', component: PreferencesSecurityComponent, data: { tab: 'security' } }
                ]
            },
            {
                path: 'account/:accountId',
                component: AccountTemplateComponent,
                canActivate: [UserAccountGuard],
                children: [
                    { path: '', component: AccountDetailsComponent, data: { tab: 'details' } },
                    { path: 'payment', component: PaymentsComponent, data: { tab: 'payment' } },
                    { path: 'payment-history', component: PaymentHistoryComponent, data: { tab: 'payment-history' } },
                    { path: 'pending-payments', component: PaymentHistoryComponent, data: { tab: 'pending-payments' } },
                    { path: 'payoff', component: PayoffComponent, data: { tab: 'payoff' } },
                    { path: 'statements', component: AccountStatementsComponent, data: { tab: 'statements' } },
                    { path: 'transaction-report', component: TransactionReportComponent, data: { tab: 'transaction-report' } },
                    {
                        path: 'authorized-party',
                        component: AuthorizedPartyComponent,
                        data: { tab: 'authorized-party' },
                        children: [
                            { path: '', component: AuthorizedPartyInProgressDetailComponent, data: { tab: 'authorized-party' } },
                            { path: 'add', component: AuthorizedPartyAddFormComponent, data: { tab: 'authorized-party' } },
                            { path: 'response', component: AuthorizedPartyResponseComponent, data: { tab: 'authorized-party' } }
                        ]
                    },
                    { path: 'amendment', component: AmendmentComponent, data: { tab: 'amendment' } },

                ]
            }
        ]
    },
    {
        path: 'registration',
        component: RegistrationComponent,
        children: [
            { path: '', redirectTo: 'registration-profile', pathMatch: 'full' },
            { path: 'registration-profile', component: RegistrationProfileComponent, data: { tab: 'Profile' } },
            { path: 'registration-linkaccount', component: RegistrationLinkAccountComponent, data: { tab: 'Account' } },
            { path: 'registration-security', component: RegistrationSecurityComponent, data: { tab: 'Security' } },
            { path: 'registration-agreement', component: RegistrationAgreementComponent, data: { tab: 'Agreement' } },
            { path: 'registration-summary', component: RegistrationSummaryComponent, data: { tab: 'Summary' } }
        ]
    },
    {
        path: 'apply',
        component: NewLandingComponent
    },
    {
        path: 'landing',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent        
    },
    {
        path: 'borrower-information',
        component: BorrowerInformationComponent
    },
    {
        path: 'entity-information',
        component: EntityInformationComponent
    },
    {
        path: 'line-of-business-information',
        component: LineOfBusinessInformationComponent
    },
    {
        path: 'ownership',
        component: OwnershipComponent
    },
    {
        path: 'financial-information',
        component: FinancialInformationComponent
    },
    {
        path: 'financial-information-income',
        component: FinancialInformationIncomeComponent
    },
    {
        path: 'financial-information-balance-sheet',
        component: FinancialInformationBalanceSheetComponent
    },
    {
        path: 'financial-information-balance-sheet-selection',
        component: FinancialInformationBalanceSheetSelectionComponent
    },
    {
        path: 'summary-and-sign',
        component: SummaryAndSignComponent
    },
    {
        path: 'confirmation',
        component: ConfirmationComponent
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
    },
    {
        path: 'electronic-statement',
        component: ElectronicStatementComponent
    },
    {
        path: 'ach-us-bank-statement',
        component: AchUSBankStatementComponent
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'vendor/:vendor',
        component: NewLandingComponent
    },
    {
        path: 'prescreenedofferacceptance',
        component: PreApprovalComponent
    },
    {
        path: 'prescreen',
        component: PreApprovalComponent
    },
    {
        path: 'resetsecurityquestions',
        component: ResetSecurityQuestionsComponent
    },    
    // place new paths above here, this catch all redirect needs to be last.
    {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
